import { createStyles, makeStyles } from '@material-ui/core/styles';
import { FunctionComponent } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Button from '@@src/components/Inputs/Button';
import fontFamily from '@@styles/typography/fontFamily';

import grey from '../../../../styles/colors/grey';

const useStyles = makeStyles(() => {
  return createStyles({
    title: {
      fontFamily: fontFamily.secondary,
      fontSize: '1.75rem',
      fontWeight: 'bold',
      marginBottom: '1rem',
    },
    description: {
      fontFamily: fontFamily.primary,
      textWrap: 'wrap',
      marginBottom: '1.5rem',
      '& a': {
        textDecoration: 'underline',
      },
    },
    section: {
      textAlign: 'left',
      padding: 16,
      borderRadius: 8,
      backgroundColor: grey.blackpearl,
      marginBottom: 16,
      '& p': {
        marginTop: 7,
        marginBottom: 0,
      },
    },
    cta: {
      display: 'flex',
      flexDirection: 'row',
      gap: 16,
      justifyContent: 'center',
      marginTop: 32,
    },
  });
});

const BlockerDetectedCard: FunctionComponent = (props) => {
  const classes = useStyles(props);
  const { t } = useTranslation('common');

  return (
    <>
      <div className={classes.title}>{t('videoPlayer.videoPlaybackErrorTitle')}</div>
      <p className={classes.description}>{t('videoPlayer.videoPlaybackErrorDescription')}</p>
      <div className={classes.section}>
        <Trans
          i18nKey="videoPlayer.videoPlaybackErrorAdblockWithTag"
        >
          <b>Ad Blockers</b>
          <p>If you are using an ad blocker, please disable it for SBS On Demand and then refresh the page.</p>
        </Trans>
      </div>
      <div className={classes.section}>
        <Trans
          i18nKey="videoPlayer.videoPlaybackErrorSecurityWithTag"
        >
          <b>Security Software</b>
          <p>
            This error may be caused by a firewall, anti-virus software or traffic blocking app/device (e.g. Pi-hole).
            Please configure exclusion rule for SBS On Demand if you have any security software installed on your device and then refresh the page.
          </p>
        </Trans>
      </div>
      <div className={classes.section}>
        <Trans
          i18nKey="videoPlayer.videoPlaybackErrorFFTrackingWithTag"
        >
          <b>Firefox Advanced Tracking Protection</b>
          <p>If this setting is activated it can stop videos from loading correctly. Please disable this setting or exclude SBS On Demand from it and then refresh the page.</p>
        </Trans>
      </div>
      <div className={classes.cta}>
        <Button
          size="large"
          buttonType="secondary"
          href="https://help.sbs.com.au/hc/en-au/articles/360002033956"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('videoPlayer.learnMore')}
        </Button>

        <Button
          size="large"
          buttonType="primary"
          onClick={() => { window.location.reload(); }}
        >
          {t('videoPlayer.refreshPage')}
        </Button>
      </div>
    </>
  );
};

export default BlockerDetectedCard;
