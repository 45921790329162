import { createStyles, makeStyles } from '@material-ui/core/styles';
import { FunctionComponent } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Button from '@@src/components/Inputs/Button';
import { OdLink } from '@@src/routes';
import fontFamily from '@@styles/typography/fontFamily';

const useStyles = makeStyles(() => {
  return createStyles({
    title: {
      fontFamily: fontFamily.secondary,
      fontSize: '1.75rem',
      fontWeight: 'bold',
      marginBottom: '1rem',
    },
    description: {
      fontFamily: fontFamily.primary,
      textWrap: 'wrap',
      marginBottom: '1.5rem',
      '& a': {
        textDecoration: 'underline',
      },
    },
    cta: {},
  });
});

const GeoBlockedCard: FunctionComponent = (props) => {
  const classes = useStyles(props);
  const { t } = useTranslation('common');

  return (
    <>
      <div className={classes.title}>
        {t('videoPlayer.errorGeoBlockTitle')}
      </div>
      <p className={classes.description}>
        <Trans
          i18nKey="videoPlayer.errorGeoBlockWithTag"
        >
          {/* eslint-disable-next-line max-len */}
          You appear to be outside of Australia. Please note that our content is only available to stream within Australia due to publishing rights. If you are in Australia and are receiving this message, please
          <OdLink to="https://help.sbs.com.au/hc/en-au/requests/new" target="_blank">contact customer service</OdLink>
          .
        </Trans>
      </p>
      <div className={classes.cta}>
        <Button
          size="large"
          buttonType="secondary"
          href="https://help.sbs.com.au/hc/en-au/articles/360002023895"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('videoPlayer.learnMore')}
        </Button>
      </div>
    </>
  );
};

export default GeoBlockedCard;
